import React, { useState } from "react";
import KonteoDesc from "../../components/KonteoDesc";
import KonteoHow from "../../components/KonteoHow";
import KonteoFeatures from "../../components/KonteoFeatures";
import KonteoCalculate from "../../components/KonteoCalculate";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import logoiKompras from "../../assets/logoiKompras.png";
import { Icon, Message } from "semantic-ui-react";
import styles from "./styles.module.css";
const Konteo = () => {
  const [message, setMessage] = useState(false);
  const messageValue = (info) => {
    setMessage(info);
  };
  return (
    <div>
      {message && (
        <div className={styles.mensagge}>
          <Message className={styles.mensaggeCont} size="massive" icon color="blue">
            <Icon name="circle notched" loading />

            <Message.Content>
              <Message.Header> Cargando...</Message.Header>
            </Message.Content>
          </Message>
        </div>
      )}
      <Navbar logo={logoiKompras} activeName={"Konteo"} />
      <KonteoDesc />
      <KonteoHow />
      <KonteoFeatures />
      <KonteoCalculate messageValue={messageValue} />

      <Footer />
    </div>
  );
};

export default Konteo;
