import React, { useEffect } from "react";

import { Grid, Image } from "semantic-ui-react";
import maxLogoTransparente from "../../assets/maxlogoTransparente.png";
import rastreoTransparente from "../../assets/rastreoTransparente.png";
import KonteoTransparente from "../../assets/konteoTransparente.svg";
import styles from "./styles.module.css";
import AOS from "aos";
import { useNavigate } from "react-router-dom";

const Product = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Grid className={styles.segProduct}>
      <Grid.Column mobile={16} tablet={16} computer={16}>
        <div id={"product"} className={styles.titleProduct}>
          NUESTROS PRODUCTOS
        </div>
      </Grid.Column>

      <Grid.Column mobile={16} tablet={16} computer={5} className={styles.columnCenter}>
        <Image
          onClick={() => {
            navigate("/konteo");
          }}
          data-aos="zoom-in-bottom"
          bordered
          rounded
          className={styles.logo}
          src={KonteoTransparente}
          target="_blank"
        />
      </Grid.Column>
      <Grid.Column mobile={16} tablet={16} computer={6} className={styles.columnCenter}>
        <Image
          onClick={() => {
            navigate("/rastreo");
          }}
          data-aos="zoom-in-bottom"
          bordered
          rounded
          className={styles.logo}
          src={rastreoTransparente}
          target="_blank"
        />
      </Grid.Column>
      <Grid.Column mobile={16} tablet={16} computer={5} className={styles.columnCenter}>
        <Image
          onClick={() => {
            navigate("/maxmonitor");
          }}
          data-aos="zoom-in-bottom"
          bordered
          rounded
          className={styles.logo}
          src={maxLogoTransparente}
          target="_blank"
        />
      </Grid.Column>
    </Grid>
  );
};

export default Product;
