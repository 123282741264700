import React, { useEffect } from "react";

import { Grid, Image } from "semantic-ui-react";
import featureBusiness from "../../assets/maxmonitor/featureBusiness.png";
import featureChart from "../../assets/maxmonitor/featureChart.png";
import featureClock from "../../assets/maxmonitor/featureClock.png";
import featureManager from "../../assets/maxmonitor/featureManager.png";
import featureMegaphone from "../../assets/maxmonitor/featureMegaphone.png";
import featurephoneBook from "../../assets/maxmonitor/featurephoneBook.png";
import featurePiechart from "../../assets/maxmonitor/featurePiechart.png";
import featurePrinter from "../../assets/maxmonitor/featurePrinter.png";
import featureUser from "../../assets/maxmonitor/featureUser.png";
import styles from "./styles.module.css";
import AOS from "aos";

const MaxMonitorFeatures = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const renderCard = () => {
    const features = [
      [
        { title: "Permite a múltiples usuarios poder responder los mensajes al mismo tiempo.", imgVar: featureManager },
        {
          title: "Medición de los tiempos de respuesta de los mensajes.",
          imgVar: featureClock,
        },
        { title: "Segmentación de contactos.", imgVar: featurephoneBook },
        {
          title: "Multiples plataformas de chat  Whatsapp, Telegram, Facebook, Instagram y Correo.",
          imgVar: featureUser,
        },
        {
          title: "Alertas de voz personalizadas.",
          imgVar: featureMegaphone,
        },
      ],
      [
        { title: "Consulta detallada de la información deseada.", imgVar: featureBusiness },
        { title: "Estadísticas", imgVar: featureChart },
        { title: "Reportes", imgVar: featurePiechart },

        {
          title: "Impresión de imágenes y mensajes.",
          imgVar: featurePrinter,
        },
      ],
    ];
    let targetCard = [];

    for (let i = 0; i < 2; i++) {
      const cardChanel = features[i].map((item, index) => (
        <div className={styles.card} key={index}>
          <div className={styles.cardHeaderCont}>
            <Image className={styles.cardHeader} src={item.imgVar} circular />
          </div>
          <div className={styles.cardBody}>
            <h2 className={styles.textLetter}>{item.title}</h2>
          </div>
        </div>
      ));
      targetCard[i] = cardChanel;
    }
    return (
      <div>
        <div className={styles.cardCont}>{targetCard[0]}</div>
        <div className={styles.cardCont}>{targetCard[1]}</div>
      </div>
    );
  };
  return (
    <Grid className={styles.segProduct}>
      <Grid.Row className={styles.contContactRow} centered>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <div id={"features"} className={styles.titleProduct}>
            CARACTERÍSTICAS
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={16} className={styles.columnCenter}>
          <div>{renderCard()} </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default MaxMonitorFeatures;
