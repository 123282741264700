import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import MaxMonitorDesc from "../../components/MaxMonitorDesc";
import MaxMonitorFeatures from "../../components/MaxMonitorFeatures";
import MaxMonitorServices from "../../components/MaxMonitorServices";
import logoiKompras from "../../assets/logoiKompras.png";

import { Icon, Message } from "semantic-ui-react";

import Footer from "../../components/Footer";
import styles from "./styles.module.css";
const MaxMonitor = () => {
  const [message, setMessage] = useState(false);
  const messageValue = (info) => {
    setMessage(info);
  };
  return (
    <div>
      {message && (
        <div className={styles.mensagge}>
          <Message className={styles.mensaggeCont} size="massive" icon color="blue">
            <Icon name="circle notched" loading />

            <Message.Content>
              <Message.Header> Cargando...</Message.Header>
            </Message.Content>
          </Message>
        </div>
      )}
      <Navbar logo={logoiKompras} activeName={"Max Monitor"} />
      <MaxMonitorDesc />
      <MaxMonitorFeatures />

      <MaxMonitorServices messageValue={messageValue} />

      <Footer />
    </div>
  );
};

export default MaxMonitor;
