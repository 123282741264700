import { isEmpty } from "lodash";

export function currencyFormat(num, integer = false) {
  if (num !== undefined && typeof num === "string" && !isEmpty(num)) {
    const newNum = parseFloat(num);
    let strNum = newNum.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    if (integer) {
      strNum = strNum.substring(0, strNum.length - 3);
    }

    return strNum;
  } else if (num === null) {
    return "";
  } else if (isEmpty(num)) {
    return "";
  } else if (num !== undefined && num !== null) {
    let strNum = num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    if (integer) {
      strNum = strNum.substring(0, strNum.length - 3);
    }
    return strNum;
  }
}
export function phoneFormat(string) {
  if (string != null) {
    const cleanString = string.replace(/[^\d]/g, "");
    if (cleanString.length === 10) {
      return cleanString.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    } else if (cleanString.length === 11) {
      return cleanString.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
    }
    return string;
  }
}
