import React, { useEffect } from "react";

import { Grid, List } from "semantic-ui-react";

import styles from "./styles.module.css";
import AOS from "aos";

const RastreoPlanningRouter = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Grid className={styles.contGrid}>
      <Grid.Row centered className={styles.contContactRow}>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <div className={styles.confirst}>
            <div className={styles.conTitle} data-aos="fade-up" id={"features"}>
              OPTIMIZACION DE RUTAS
            </div>
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <div className={styles.conCenter} data-aos="fade-up">
            <List>
              <List.Item className={styles.listArt}>
                <List.Icon className={styles.iconList} name="lightbulb outline" />
                <List.Content>
                  <div className={styles.textLetter}>Minimiza los costos operacionales </div>
                  <p data-aos="fade-up" className={styles.textLetterTitle}>
                    ahorrando aproximadamente un 20% sobre la flota y las rutas.
                  </p>
                </List.Content>
              </List.Item>
              <List.Item className={styles.listArt}>
                <List.Icon className={styles.iconList} name="lightbulb outline" />
                <List.Content>
                  <div className={styles.textLetter}>Aumenta la satisfacción de los clientes </div>
                  <p data-aos="fade-up" className={styles.textLetterTitle}>
                    incrementa el número de pedidos completados con éxito, mejorando la calidad del servicio y de la experiencia del cliente.
                  </p>
                </List.Content>{" "}
              </List.Item>
              <List.Item className={styles.listArt}>
                <List.Icon className={styles.iconList} name="lightbulb outline" />
                <List.Content>
                  <div className={styles.textLetter}>Mejora la gestión de la flota vehicular </div>
                  <p data-aos="fade-up" className={styles.textLetterTitle}>
                    reduce hasta un 10% el número de vehículos necesarios para realizar las entregas.
                  </p>
                </List.Content>{" "}
              </List.Item>
            </List>
          </div>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={16} computer={8}>
          <div className={styles.conCenter} data-aos="fade-up">
            <List>
              <List.Item className={styles.listArt}>
                <List.Icon className={styles.iconList} name="lightbulb outline" />
                <List.Content>
                  <div className={styles.textLetter}>Minimiza el uso de combustible </div>
                  <p data-aos="fade-up" className={styles.textLetterTitle}>
                    reduce el número de kilómetros a recorrer para realizar las entregas, con su consecuente ahorro de combustible.
                  </p>
                </List.Content>{" "}
              </List.Item>
              <List.Item className={styles.listArt}>
                <List.Icon className={styles.iconList} name="lightbulb outline" />
                <List.Content>
                  <div className={styles.textLetter}>Mejora la trazabilidad y el control de las entregas </div>
                  <p data-aos="fade-up" className={styles.textLetterTitle}>
                    mejora la identificación y el seguimiento de las mercancías y servicios en todo momento.
                  </p>
                </List.Content>{" "}
              </List.Item>

              <List.Item className={styles.listArt}>
                <List.Icon className={styles.iconList} name="lightbulb outline" />
                <List.Content>
                  <div className={styles.textLetter}>Mejora la resolución de incidencias </div>
                  <p data-aos="fade-up" className={styles.textLetterTitle}>
                    gestiona de manera más ágil y rápida las incidencias del día a día.
                  </p>
                </List.Content>{" "}
              </List.Item>
            </List>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default RastreoPlanningRouter;
