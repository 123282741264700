import React, { useState, useEffect, useRef } from "react";
import { isEmpty } from "lodash";
import { Grid, Checkbox, Form } from "semantic-ui-react";
import styles from "./styles.module.css";
import AOS from "aos";
import Contact from "../Contact";
import html2canvas from "html2canvas";

const MaxMonitorServices = (props) => {
  const componentRef = useRef(null);
  const { messageValue } = props;

  const [service, setService] = useState("");
  const [agent, setAgent] = useState("");
  const [projectCheck, setProjectCheck] = useState(false);
  const [automaticAttention, setAutomaticAttention] = useState(false);
  const [personalizedBot, setpersonalizedBot] = useState(false);
  const [useDepartments, setUseDepartments] = useState(false);
  const [apiCheck, setApiCheck] = useState(false);
  const [installPractice, setInstallPractice] = useState(false);
  const [facebookCheck, setFacebookCheck] = useState(false);
  const [whatsappCheck, setWhatsappCheck] = useState(false);
  const [InstagramCheck, setInstagramCheck] = useState(false);
  const [telegramCheck, setTelegramCheck] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false);
  const handleDownloadImage = async () => {
    const element = componentRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL();

    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      const blobImage = DataURIToBlob(data);
      return blobImage;
    }
  };
  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleformat = (e) => {
    const { name, value } = e.target;
    if (!isEmpty(value)) {
      let nf = new Intl.NumberFormat("en-US");
      const string1DesComma = value.replaceAll(",", "");
      const valueUpd = nf.format(string1DesComma);

      switch (name) {
        case "agent":
          setAgent(valueUpd);
          break;

        default:
          break;
      }
    }
  };

  const handleMateInput = (e) => {
    const { name, value } = e.target;
    const esValido = e.target.validity.valid;

    if (esValido) {
      switch (name) {
        case "agent":
          setAgent(value);
          break;

        default:
          break;
      }
    }
  };

  return (
    <div>
      <div id={"price"} ref={componentRef}>
        <Grid className={styles.contContact}>
          <Grid.Row className={styles.contContactRow} centered>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className={styles.checkCalc}>
                <div className={styles.titleContactForm}>COTIZAR</div>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16} className={styles.columnForm}>
              <div className={styles.contForm} id={"contact"} data-aos="flip-left">
                <div className={styles.titleSend}>Explique brevemente los servicios que presta la empresa</div>
                <textarea
                  className={styles.input}
                  id="w3review"
                  name="w3review"
                  onChange={(e) => setService(e.target.value)}
                  value={service}
                  rows="4"
                  cols="50">
                  At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies.
                </textarea>
                <div className={styles.titleSend}>Cantidad de Agentes</div>

                <input
                  type="text"
                  pattern="[0-9 _,]*"
                  name={"agent"}
                  onBlur={handleformat}
                  className={styles.inputNumber}
                  value={agent}
                  onChange={(e) => handleMateInput(e)}
                />
                <div className={styles.contTextData}>
                  <div className={styles.titleSend}>Chequee las opciones para las que aplica su empresa</div>

                  <div className={styles.contTextCanal}>
                    <div className={styles.checkBoxPlan}>
                      <Form inverted className={styles.groupPlan}>
                        <Form.Group inline>
                          <div className={styles.titleSendPlan}>{"Canales"}</div>

                          <Checkbox
                            label="Whatsapp"
                            name="whatsappCheck"
                            style={{ fontSize: "18px", marginLeft: "5px", marginRight: "22px" }}
                            value="whatsappCheck"
                            checked={whatsappCheck}
                            onChange={() => {
                              setWhatsappCheck(!whatsappCheck);
                            }}
                          />
                          <Checkbox
                            label="Telegram"
                            name="telegramCheck"
                            style={{ fontSize: "18px", marginLeft: "5px", marginRight: "22px" }}
                            value="telegramCheck"
                            checked={telegramCheck}
                            onChange={(value) => {
                              setTelegramCheck(!telegramCheck);
                              console.log(value);
                            }}
                          />
                          <Checkbox
                            label="Facebook"
                            name="facebookCheck"
                            style={{ fontSize: "18px", marginLeft: "5px", marginRight: "22px" }}
                            value="facebookCheck"
                            checked={facebookCheck}
                            onChange={() => {
                              setFacebookCheck(!facebookCheck);
                            }}
                          />
                          <Checkbox
                            label="instagram"
                            name="InstagramCheck"
                            style={{ fontSize: "18px", marginLeft: "5px", marginRight: "22px" }}
                            value="instagramCheck"
                            checked={InstagramCheck}
                            onChange={() => {
                              setInstagramCheck(!InstagramCheck);
                            }}
                          />
                          <Checkbox
                            label="Correo"
                            name="emailCheck"
                            style={{ fontSize: "18px", marginLeft: "5px", marginRight: "22px" }}
                            value="emailCheck"
                            checked={emailCheck}
                            onChange={() => {
                              setEmailCheck(!emailCheck);
                            }}
                          />
                        </Form.Group>
                      </Form>
                    </div>
                  </div>

                  <div className={styles.contCheck}>
                    <div>
                      <div className={styles.labelTitleData}>
                        <Checkbox
                          onChange={() => {
                            setProjectCheck(!projectCheck);
                          }}
                          className={styles.checkBox}
                          checked={projectCheck}
                        />
                        {"Manejo de Proyectos."}
                      </div>

                      <div className={styles.contTextData}>
                        <div className={styles.labelTitleData}>
                          <Checkbox
                            onChange={() => {
                              setApiCheck(!apiCheck);
                            }}
                            className={styles.checkBox}
                            checked={apiCheck}
                          />
                          {"Integración con API."}
                        </div>
                      </div>

                      <div className={styles.contTextData}>
                        <div className={styles.labelTitleData}>
                          <Checkbox
                            onChange={() => {
                              setAutomaticAttention(!automaticAttention);
                            }}
                            className={styles.checkBox}
                            checked={automaticAttention}
                          />
                          {"Opciones para Auto Atención."}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.labelTitleData}>
                        <Checkbox
                          onChange={() => {
                            setpersonalizedBot(!personalizedBot);
                          }}
                          className={styles.checkBox}
                          checked={personalizedBot}
                        />
                        {"Bot Personalizado"}
                      </div>

                      <div className={styles.contTextData}>
                        <div className={styles.labelTitleData}>
                          <Checkbox
                            onChange={() => {
                              setUseDepartments(!useDepartments);
                            }}
                            className={styles.checkBox}
                            checked={useDepartments}
                          />
                          {"Aplica Departamentos"}
                        </div>

                        <div className={styles.contTextData}>
                          <div className={styles.labelTitleData}>
                            <Checkbox
                              onChange={() => {
                                setInstallPractice(!installPractice);
                              }}
                              className={styles.checkBox}
                              checked={installPractice}
                            />
                            {"Instalación y Entrenamiento."}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <Contact
        planBool={
          !isEmpty(service) ||
          !isEmpty(agent) ||
          projectCheck ||
          automaticAttention ||
          personalizedBot ||
          useDepartments ||
          installPractice ||
          apiCheck ||
          facebookCheck ||
          whatsappCheck ||
          InstagramCheck ||
          telegramCheck ||
          emailCheck
        }
        handleImage={handleDownloadImage}
        plan={"maxmonitor"}
        messageValue={messageValue}
        activeName={"MaxMonitor"}
      />
    </div>
  );
};

export default MaxMonitorServices;
