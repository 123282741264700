import React, { useEffect } from "react";

import { Grid, Header, Image } from "semantic-ui-react";
import we from "../../assets/we.jpg";
import styles from "./styles.module.css";
import AOS from "aos";

const We = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container className={styles.contWe} verticalAlign="middle">
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Header style={{ fontSize: "40px" }} data-aos="fade-up">
            SOBRE NOSOTROS
          </Header>

          <p data-aos="fade-up" style={{ fontSize: "1.63em" }} id={"we"}>
            Somos una empresa dedicada a desarrollar, implementar, crear y ejecutar soluciones tecnológicas para mejorar el manejo y la eficiencia de las
            actividades en los distintos departamentos de las empresas.
          </p>
        </Grid.Column>
        <Grid.Column floated="right" mobile={16} tablet={16} computer={8}>
          <Image data-aos="fade-up" bordered rounded className={styles.imgWe} src={we} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default We;
