import React, { useEffect } from "react";

import { Grid, List } from "semantic-ui-react";

import styles from "./styles.module.css";
import AOS from "aos";

const KonteoFeatures = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Grid className={styles.contWe}>
      <Grid.Row className={styles.contContactRow} centered>
        <Grid.Column width={16}>
          <div className={styles.conTitle} data-aos="fade-up" id={"features"}>
            CARATERISTICAS
          </div>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={16} computer={8}>
          <div className={styles.conCenter} data-aos="fade-up">
            <List bulleted>
              <List.Item className={styles.textLetter}>Inicia un inventario rápido desde cero.</List.Item>
              <List.Item className={styles.textLetter}>Importación y exportación de datos.</List.Item>
              <List.Item className={styles.textLetter}>Elimina las tareas manuales.</List.Item>
              <List.Item className={styles.textLetter}>Integración con otras plataformas.</List.Item>
              <List.Item className={styles.textLetter}>Fácil interfaz para los usuarios.</List.Item>
            </List>
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <div className={styles.conCenter} data-aos="fade-up">
            <List bulleted>
              <List.Item className={styles.textLetter}>Ver el rendimiento por agente.</List.Item>
              <List.Item className={styles.textLetter}>Tableros con estadísticas en tiempo real.</List.Item>
              <List.Item className={styles.textLetter}>Utiliza el celular para el conteo de inventario.</List.Item>
              <List.Item className={styles.textLetter}>Lectura de código de barras por cámara.</List.Item>
              <List.Item className={styles.textLetter}>No tienes que cambiar de sistema.</List.Item>
            </List>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default KonteoFeatures;
