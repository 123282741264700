import React, { useState, useEffect, useRef } from "react";
import { Grid, Checkbox, List, Icon, Form } from "semantic-ui-react";
import { find, isEmpty, isNumber } from "lodash";
import styles from "./styles.module.css";
import AOS from "aos";
import { currencyFormat } from "../../constants/formats";
import axios from "axios";
import Contact from "../../components/Contact";
import html2canvas from "html2canvas";
const KonteoCalculate = (props) => {
  const { messageValue } = props;
  const componentRef = useRef(null);
  const [allItems, setAllItems] = useState("");
  const [countBox, setCountBox] = useState("");
  const [countUser, setCountUser] = useState("");
  const [facility, setFacility] = useState(false);
  const [encodeSections, setEncodeSections] = useState(false);
  const [accompanimentHours, setAccompanimentHours] = useState(false);
  const [accompanimentHoursText, setAccompanimentHoursText] = useState("");
  const [countBoxCost, setCountBoxCost] = useState("");
  const [countUserCost, setCountUserCost] = useState("");
  const [facilityCost, setFacilityCost] = useState("");
  const [encodeSectionsCost, setEncodeSectionsCost] = useState("");
  const [accompanimentHoursTextCost, setAccompanimentHoursTextCost] = useState("");
  const [agenteHoursPrice, setAgenteHoursPrice] = useState("");
  const [encodeLocBinPrice, setEncodeLocBinPrice] = useState("");
  const [extraWarehousePrice, setExtraWarehousePrice] = useState("");
  const [firstWarehousePrice, setFirstWarehousePrice] = useState("");
  const [minWarehouses, setMinWarehouses] = useState("");
  const [installationPrice, setInstallationPrice] = useState("");
  const [minAgentHoursCount, setMinAgentHoursCount] = useState("");
  const [minUserCount, setMinUserCount] = useState("");
  const [userPrice, setUserPrice] = useState("");
  const [radioButtom, setRadioButtom] = useState("");
  const [integrationSystemsCheck, setIntegrationSystemsCheck] = useState(false);
  const [organizeProductsSectionsCheck, setOrganizeProductsSectionsCheck] = useState(false);
  const [assignLocationsCheck, setAssignLocationsCheck] = useState(false);
  const [physicalInventoryCheck, setPhysicalInventoryCheck] = useState(false);
  const [advisoryCheck, setAdvisoryCheck] = useState(false);
  const [barcodePrice, setBarcodePrice] = useState(false);
  const [opcIntegracionSystem, setOpcIntegracionSystem] = useState("");
  const [opcProductBinCount, setOpcProductBinCount] = useState("");
  const [opcProductBinPrice, setOpcProductBinPrice] = useState("");
  const [opcAsingLocationCount, setOpcAsingLocationCount] = useState("");
  const [opcAsingLocationPrice, setOpcAsingLocationPrice] = useState("");
  const [opcInvFisCount, setOpcInvFisCount] = useState("");
  const [opcInvFisPrice, setOpcInvFisPrice] = useState("");
  const [opcAdvisory, setOpcAdvisory] = useState("");
  const [opcbarcodePriceCount, setOpcbarcodePriceCount] = useState("");
  const [opcbarcodePricePrice, setOpcbarcodePricePrice] = useState("");

  useEffect(() => {
    apiHandler();
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMateInput = (e) => {
    const { name, value } = e.target;
    const esValido = e.target.validity.valid;

    if (esValido) {
      let mult = "";
      switch (name) {
        case "countBox":
          if (parseInt(value) > minWarehouses && !isEmpty(value)) {
            const string1DesComma = value.replaceAll(",", "");
            mult = String((parseInt(string1DesComma) - minWarehouses) * extraWarehousePrice);
            //  +parseInt(firstWarehousePrice);
          } else {
            mult = "";
          }
          setCountBox(value);
          setCountBoxCost(mult);
          break;

        case "countUser":
          if (parseInt(value) > minUserCount && !isEmpty(value)) {
            const string1DesComma = value.replaceAll(",", "");
            mult = String((parseInt(string1DesComma) - minUserCount) * userPrice);
          } else {
            mult = "";
          }
          setCountUser(value);
          setCountUserCost(mult);
          break;
        case "accompanimentHoursText":
          if (parseInt(value) > minAgentHoursCount && !isEmpty(value)) {
            const string1DesComma = value.replaceAll(",", "");
            mult = String(parseInt(string1DesComma) * agenteHoursPrice);
          } else {
            mult = "";
          }

          setAccompanimentHoursText(value);
          setAccompanimentHoursTextCost(mult);
          break;

        case "opcProductBinCount":
          setOpcProductBinCount(value);
          break;

        case "opcProductBinPrice":
          setOpcProductBinPrice(value);
          break;

        case "opcAsingLocationCount":
          setOpcAsingLocationCount(value);
          break;

        case "opcAsingLocationPrice":
          setOpcAsingLocationPrice(value);
          break;

        case "opcInvFisCount":
          setOpcInvFisCount(value);
          break;

        case "opcInvFisPrice":
          setOpcInvFisPrice(value);
          break;

        case "advisoryCheck":
          setAdvisoryCheck(value);
          break;
        case "opcbarcodePriceCount":
          setOpcbarcodePriceCount(value);
          break;
        case "opcbarcodePricePrice ":
          setOpcbarcodePricePrice(value);
          break;

        default:
          break;
      }
    }
  };

  const handleMateChecked = (value, name) => {
    if (!isEmpty(radioButtom)) {
      switch (name) {
        case "facility":
          if (value) {
            setFacility(value);
            setFacilityCost(String(installationPrice));
          } else {
            setFacility(value);
            setFacilityCost("");
          }
          break;
        case "encodeSections":
          if (value) {
            setEncodeSections(value);
            setEncodeSectionsCost(String(encodeLocBinPrice));
          } else {
            setEncodeSections(value);
            setEncodeSectionsCost("");
          }
          break;

        default:
          break;
      }
    }
  };
  const apiHandler = async () => {
    let dataKonteo = [];
    await axios
      .get("https://rastreo.wsmax.net:9050/konteoPrices")
      .then(function (response) {
        dataKonteo = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {});

    setOpcAsingLocationPrice(String(dataKonteo[0].assignLocBinPrice));
    setOpcbarcodePricePrice(String(dataKonteo[0].barcodePrice));
    setOpcInvFisPrice(String(dataKonteo[0].stockTakingPrice));
    setOpcProductBinPrice(String(dataKonteo[0].orderLocBinPrice));
    setAllItems(dataKonteo);
  };
  const accompanimentHoursHandler = (value) => {
    if (!isEmpty(radioButtom)) {
      setAccompanimentHours(value);
      if (value) {
        setAccompanimentHoursText(minAgentHoursCount);

        setAccompanimentHoursTextCost(String(minAgentHoursCount * agenteHoursPrice));
      } else {
        setAccompanimentHoursText(minAgentHoursCount);
        setAccompanimentHoursTextCost("");
      }
    }
  };
  const renderListPlan = () => {
    const planes = [
      { text: "Exportar CSV", basic: "check", advanced: "check", professional: "check" },
      { text: "Estadísticas", basic: "", advanced: "check", professional: "check" },
      { text: "Registro de Lotes", basic: "", advanced: "", professional: "check" },
      { text: "Reporte Básico", basic: "", advanced: "check", professional: "" },
      { text: "Reportes Avanzados", basic: "", advanced: "", professional: "check" },
      { text: "Tablero Básico", basic: "check", advanced: "", professional: "" },
      { text: "Tablero Avanzado", basic: "", advanced: "check", professional: "check" },
      // { text: "Cantidad Productos", basic: "Hasta 7000", advanced: "Hasta 15,000", professional: "Mas de 15,000" },
    ];
    const CardChanel = planes.map((item, index) => (
      <List.Item key={index}>
        <List.Content key={index} floated="right" className={styles.contList}>
          <List.Content className={styles.labelList}>{item.basic === "check" ? <Icon name="check" /> : item.basic}</List.Content>
          <List.Content className={styles.labelList}>{item.advanced === "check" ? <Icon name="check" /> : item.advanced}</List.Content>
          <List.Content className={styles.labelList}>{item.professional === "check" ? <Icon name="check" /> : item.professional}</List.Content>
        </List.Content>
        <List.Content className={styles.labelTitleList}>{item.text}</List.Content>
      </List.Item>
    ));
    return CardChanel;
  };

  const handlerRadio = (string) => {
    switch (string) {
      case "advanced":
        const basicData = find(allItems, { label: "Avanzado" });
        setAgenteHoursPrice(basicData.agenteHoursPrice);
        setEncodeLocBinPrice(basicData.encodeLocBinPrice);
        setExtraWarehousePrice(basicData.extraWarehousePrice);
        setFirstWarehousePrice(basicData.firstWarehousePrice);
        setInstallationPrice(basicData.installationPrice);
        setMinAgentHoursCount(basicData.minAgentHoursCount);
        setMinUserCount(basicData.minUserCount);
        setUserPrice(basicData.userPrice);
        setCountUser(basicData.minUserCount);
        setCountBoxCost("");
        setCountBox(String(basicData.minWarehouses));
        setMinWarehouses(basicData.minWarehouses);
        setCountUserCost(String(""));
        setFacility(true);
        setFacilityCost(String(basicData.installationPrice));
        setRadioButtom(string);
        if (accompanimentHours) {
          setAccompanimentHoursText(basicData.minAgentHoursCount);
          setAccompanimentHoursTextCost(String(basicData.minAgentHoursCount * basicData.agenteHoursPrice));
        }
        if (encodeSections) {
          setEncodeSectionsCost(basicData.encodeLocBinPrice);
        }
        break;
      case "professional":
        const avanceData = find(allItems, { label: "Profesional" });
        setAgenteHoursPrice(avanceData.agenteHoursPrice);
        setEncodeLocBinPrice(avanceData.encodeLocBinPrice);
        setExtraWarehousePrice(avanceData.extraWarehousePrice);
        setFirstWarehousePrice(avanceData.firstWarehousePrice);
        setInstallationPrice(avanceData.installationPrice);
        setMinAgentHoursCount(avanceData.minAgentHoursCount);

        setMinUserCount(avanceData.minUserCount);
        setUserPrice(avanceData.userPrice);
        setCountUser(avanceData.minUserCount);
        setCountBoxCost("");
        setCountBox(String(avanceData.minWarehouses));
        setMinWarehouses(avanceData.minWarehouses);
        setCountUserCost(String(""));
        setFacility(true);
        setFacilityCost(String(avanceData.installationPrice));
        setRadioButtom(string);
        if (accompanimentHours) {
          setAccompanimentHoursText(avanceData.minAgentHoursCount);
          setAccompanimentHoursTextCost(String(avanceData.minAgentHoursCount * avanceData.agenteHoursPrice));
        }
        if (encodeSections) {
          setEncodeSectionsCost(avanceData.encodeLocBinPrice);
        }
        break;
      case "basic":
        const professionalData = find(allItems, { label: "Básico" });
        setAgenteHoursPrice(professionalData.agenteHoursPrice);
        setEncodeLocBinPrice(professionalData.encodeLocBinPrice);
        setExtraWarehousePrice(professionalData.extraWarehousePrice);
        setFirstWarehousePrice(professionalData.firstWarehousePrice);
        setInstallationPrice(professionalData.installationPrice);
        setMinAgentHoursCount(professionalData.minAgentHoursCount);
        setMinUserCount(professionalData.minUserCount);
        setUserPrice(professionalData.userPrice);
        setCountUser(professionalData.minUserCount);
        setCountBoxCost("");
        setCountBox(String(professionalData.minWarehouses));
        setMinWarehouses(professionalData.minWarehouses);
        setCountUserCost(String(""));
        setFacility(true);
        setFacilityCost(String(professionalData.installationPrice));
        setRadioButtom(string);
        if (accompanimentHours) {
          setAccompanimentHoursText(professionalData.minAgentHoursCount);
          setAccompanimentHoursTextCost(String(professionalData.minAgentHoursCount * professionalData.agenteHoursPrice));
        }
        if (encodeSections) {
          setEncodeSectionsCost(professionalData.encodeLocBinPrice);
        }
        break;

      default:
        break;
    }
  };

  const handleMult = (string1, string2) => {
    if (isEmpty(string1) || isEmpty(string2)) {
      return "";
    } else {
      const string1DesComma = string1.replaceAll(",", "");

      return currencyFormat(String(parseInt(string1DesComma) * parseInt(string2)));
    }
  };

  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  const handleDownloadImage = async () => {
    const element = componentRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL();

    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
    const blobImage = DataURIToBlob(data);
    return blobImage;
  };

  const handleformat = (e) => {
    const { name, value } = e.target;
    if (!isEmpty(value)) {
      let nf = new Intl.NumberFormat("en-US");
      const string1DesComma = value.replaceAll(",", "");
      const valueUpd = nf.format(string1DesComma);
      let valueMin = "";
      switch (name) {
        case "countBox":
          valueMin = valueUpd;
          if (parseInt(valueUpd) < parseInt(minWarehouses)) {
            valueMin = String(minWarehouses);

            setCountBoxCost("");
          }
          setCountBox(valueMin);
          break;
        case "countUser":
          valueMin = valueUpd;
          if (parseInt(valueUpd) < parseInt(minUserCount)) {
            valueMin = String(minUserCount);

            setCountUserCost("");
          }

          setCountUser(valueMin);

          break;
        case "accompanimentHoursText":
          valueMin = valueUpd;

          if (parseInt(valueUpd) < parseInt(minAgentHoursCount)) {
            valueMin = String(minAgentHoursCount);
            const string1DesComma = valueMin.replaceAll(",", "");
            let mult = String(parseInt(string1DesComma) * agenteHoursPrice);
            setAccompanimentHoursTextCost(mult);
          }
          setAccompanimentHoursText(valueMin);
          break;

        case "opcProductBinCount":
          setOpcProductBinCount(valueUpd);
          break;

        case "opcAsingLocationCount":
          setOpcAsingLocationCount(valueUpd);
          break;

        case "opcInvFisCount":
          setOpcInvFisCount(valueUpd);
          break;

        case "opcbarcodePriceCount":
          setOpcbarcodePriceCount(valueUpd);
          break;

        default:
          break;
      }
    }
  };

  return (
    <div>
      <div ref={componentRef} id={"report"}>
        <Grid className={styles.segProduct}>
          <Grid.Row centered className={styles.contContactRow}>
            <Grid.Column mobile={16} tablet={16} computer={8} textAlign="center">
              <div className={styles.titleProduct} id={"price"} data-aos="flip-up">
                PRECIOS Y COTIZACIONES
              </div>
              <div className={styles.contCal} data-aos="flip-up">
                <div className={styles.contCalculator}>
                  <div>
                    <div className={styles.labelTitlePlan}>{"Plan"}</div>
                    <div className={styles.contText}>
                      <div className={styles.checkBoxPlan}>
                        <Form inverted className={styles.groupPlan}>
                          <Form.Group inline>
                            <Form.Radio
                              label="Básico"
                              name="basic"
                              style={{ fontSize: "17px" }}
                              value="basic"
                              checked={radioButtom === "basic"}
                              onChange={() => {
                                handlerRadio("basic");
                              }}
                            />

                            <Form.Radio
                              label="Avanzado"
                              name="advanced"
                              style={{ fontSize: "17px" }}
                              value="advanced"
                              checked={radioButtom === "advanced"}
                              onChange={() => {
                                handlerRadio("advanced");
                              }}
                            />

                            <Form.Radio
                              label="Profesional"
                              name="professional"
                              style={{ fontSize: "17px" }}
                              value="professional"
                              checked={radioButtom === "professional"}
                              onChange={() => {
                                handlerRadio("professional");
                              }}
                            />
                          </Form.Group>
                        </Form>
                      </div>
                      <div className={styles.labelPrice}>{currencyFormat(firstWarehousePrice)}</div>
                    </div>
                    <div className={styles.contText}>
                      <div className={styles.labelTitle}>{"Cantidad de almacenes"}</div>

                      <div className={styles.searchInputStyleNumber}>
                        <input
                          type="text"
                          pattern="[0-9 _,]*"
                          name={"countBox"}
                          onBlur={handleformat}
                          className={styles.inputSearch}
                          value={countBox}
                          onChange={(e) => handleMateInput(e)}
                        />
                      </div>
                      <div className={styles.labelPrice}>{currencyFormat(countBoxCost)}</div>
                    </div>
                    <div className={styles.contText}>
                      <div className={styles.labelTitle}>{"Cantidad de Usuarios"}</div>

                      <div className={styles.searchInputStyleNumber}>
                        <input
                          type="text"
                          onBlur={handleformat}
                          pattern="[0-9 _,]*"
                          name={"countUser"}
                          className={styles.inputSearch}
                          value={countUser}
                          onChange={(e) => handleMateInput(e)}
                        />
                      </div>
                      <div className={styles.labelPrice}>{currencyFormat(countUserCost)}</div>
                    </div>
                    <div className={styles.contText}>
                      <div className={styles.labelTitle}>
                        <Checkbox
                          onChange={(e, value) => {
                            handleMateChecked(value.checked, "facility");
                          }}
                          className={styles.checkBox}
                          checked={facility}
                        />
                        {"Instalación y Entrenamiento"}
                      </div>
                      <div className={styles.labelPrice}>{currencyFormat(facilityCost)}</div>
                    </div>
                    <div className={styles.contText}>
                      <div className={styles.labelTitle}>
                        <Checkbox
                          checked={accompanimentHours}
                          onChange={(e, value) => accompanimentHoursHandler(value.checked)}
                          className={styles.checkBox}
                        />
                        {"Soporte presencial"}
                        <span className={styles.labelOpc}>{"(Opcional)"}</span>
                      </div>
                      {accompanimentHours && (
                        <div className={styles.searchInputStyleNumber}>
                          <input
                            type="text"
                            pattern="[0-9 _,]*"
                            onBlur={handleformat}
                            name={"accompanimentHoursText"}
                            className={styles.inputSearch}
                            value={accompanimentHoursText}
                            onChange={(e) => handleMateInput(e)}
                          />
                        </div>
                      )}

                      <div className={styles.labelPrice}>{currencyFormat(accompanimentHoursTextCost)}</div>
                    </div>

                    <div className={styles.contText}>
                      <div className={styles.labelTitle}>
                        <Checkbox
                          checked={encodeSections}
                          onChange={(e, value) => {
                            handleMateChecked(value.checked, "encodeSections");
                          }}
                          className={styles.checkBox}
                        />
                        {"Codificar Tramos"}
                      </div>
                      <div className={styles.labelPrice}>{currencyFormat(encodeSectionsCost)}</div>
                    </div>

                    {radioButtom === "professional" && (
                      <div className={styles.contText}>
                        <div className={styles.labelTitle}>
                          <Checkbox checked={true} className={styles.checkBox} />
                          {"Registro de Lotes"}
                        </div>
                      </div>
                    )}
                    <div className={styles.contTotal}>
                      <div className={styles.labelTitleTotal}>{"TOTAL A PAGAR"}</div>
                      <div className={styles.labelPriceTotal}>
                        {currencyFormat(
                          String(
                            parseInt(!isNumber(firstWarehousePrice) ? "0" : firstWarehousePrice) +
                              parseInt(isEmpty(countBoxCost) ? "0" : countBoxCost) +
                              parseInt(isEmpty(countUserCost) ? "0" : countUserCost) +
                              parseInt(isEmpty(accompanimentHoursTextCost) ? "0" : accompanimentHoursTextCost) +
                              parseInt(isEmpty(facilityCost) ? "0" : facilityCost) +
                              parseInt(isEmpty(encodeSectionsCost) ? "0" : encodeSectionsCost)
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <div className={styles.titleProduct} data-aos="flip-down">
                SERVICIOS OPCIONALES
              </div>

              <div className={styles.contTextData} data-aos="flip-down">
                <div className={styles.labelTitleData}>
                  <Checkbox
                    onChange={() => {
                      setIntegrationSystemsCheck(!integrationSystemsCheck);
                    }}
                    className={styles.checkBox}
                    checked={integrationSystemsCheck}
                  />
                  {"Integración con otros Sistemas."}
                </div>
              </div>
              {integrationSystemsCheck && (
                <div className={styles.contTextData}>
                  <div className={styles.labelTitleData}>
                    <textarea
                      rows="4"
                      cols="50"
                      style={{ width: "100%" }}
                      onChange={(e) => setOpcIntegracionSystem(e.target.value)}
                      className={styles.inputSearch}
                      placeholder="Incluir nombre del sistema, le estaremos contactando"
                      value={opcIntegracionSystem}
                    />
                  </div>
                </div>
              )}
              <div className={styles.contTextData} data-aos="flip-down">
                <div className={styles.labelTitleData}>
                  <Checkbox
                    onChange={(e, value) => {
                      setBarcodePrice(!barcodePrice);
                    }}
                    className={styles.checkBox}
                    checked={barcodePrice}
                  />
                  {"Asignar Códigos de Barra a Productos."}
                </div>
              </div>
              {barcodePrice && (
                <div className={styles.contTextData}>
                  <div className={styles.contTextinput}>
                    <div className={styles.contFlex}>
                      <div className={styles.contTitle}>Cantidad</div>
                      <input
                        type="text"
                        name={"opcbarcodePriceCount"}
                        onBlur={handleformat}
                        pattern="[0-9 _,]*"
                        className={styles.inputList}
                        value={opcbarcodePriceCount}
                        onChange={(e) => handleMateInput(e)}
                      />
                    </div>
                    <div className={styles.contFlex}>
                      <div className={styles.contTitle}>Precio</div>
                      <input
                        type="text"
                        pattern="[0-9]{0,13}"
                        name={"opcbarcodePricePrice"}
                        className={styles.inputList}
                        value={opcbarcodePricePrice}
                        onChange={() => handleMateInput(opcbarcodePricePrice)}
                      />
                    </div>
                    <div className={styles.contFlex}>
                      <div className={styles.contTitle}>Total</div>
                      <input
                        type="text"
                        pattern="[0-9]{0,13}"
                        className={styles.inputList}
                        value={handleMult(opcbarcodePriceCount, opcbarcodePricePrice)}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.contTextData} data-aos="flip-down">
                <div className={styles.labelTitleData}>
                  <Checkbox
                    onChange={(e, value) => {
                      setOrganizeProductsSectionsCheck(!organizeProductsSectionsCheck);
                    }}
                    className={styles.checkBox}
                    checked={organizeProductsSectionsCheck}
                  />
                  {"Organizar Productos en Tramos."}
                </div>
              </div>
              {organizeProductsSectionsCheck && (
                <div className={styles.contTextData}>
                  <div className={styles.contTextinput}>
                    <div className={styles.contFlex}>
                      <div className={styles.contTitle}>Cantidad</div>
                      <input
                        type="text"
                        name={"opcProductBinCount"}
                        pattern="[0-9 _,]*"
                        onBlur={handleformat}
                        className={styles.inputList}
                        value={opcProductBinCount}
                        onChange={(e) => handleMateInput(e)}
                      />
                    </div>
                    <div className={styles.contFlex}>
                      <div className={styles.contTitle}>Precio</div>
                      <input type="text" pattern="[0-9]{0,13}" name={"opcProductBinPrice"} className={styles.inputList} value={opcProductBinPrice} />
                    </div>
                    <div className={styles.contFlex}>
                      <div className={styles.contTitle}>Total</div>
                      <input
                        type="text"
                        pattern="[0-9]{0,13}"
                        name={"countBox"}
                        className={styles.inputList}
                        value={handleMult(opcProductBinCount, opcProductBinPrice)}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.contTextData} data-aos="flip-down">
                <div className={styles.labelTitleData}>
                  <Checkbox
                    onChange={(e, value) => {
                      setAssignLocationsCheck(!assignLocationsCheck);
                    }}
                    className={styles.checkBox}
                    checked={assignLocationsCheck}
                  />
                  {"Asignar Ubicaciones."}
                </div>
              </div>
              {assignLocationsCheck && (
                <div className={styles.contTextData}>
                  <div className={styles.contTextinput}>
                    <div className={styles.contFlex}>
                      <div className={styles.contTitle}>Cantidad</div>
                      <input
                        type="text"
                        pattern="[0-9 _,]*"
                        onBlur={handleformat}
                        name={"opcAsingLocationCount"}
                        className={styles.inputList}
                        value={opcAsingLocationCount}
                        onChange={(e) => handleMateInput(e)}
                      />
                    </div>
                    <div className={styles.contFlex}>
                      <div className={styles.contTitle}>Precio</div>
                      <input type="text" pattern="[0-9]{0,13}" name={"opcAsingLocationPrice"} className={styles.inputList} value={opcAsingLocationPrice} />
                    </div>
                    <div className={styles.contFlex}>
                      <div className={styles.contTitle}>Total</div>
                      <input
                        type="text"
                        pattern="[0-9]{0,13}"
                        className={styles.inputList}
                        value={handleMult(opcAsingLocationCount, opcAsingLocationPrice)}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.contTextData} data-aos="flip-down">
                <div className={styles.labelTitleData}>
                  <Checkbox
                    onChange={(e, value) => {
                      setPhysicalInventoryCheck(!physicalInventoryCheck);
                    }}
                    className={styles.checkBox}
                    checked={physicalInventoryCheck}
                  />
                  {"Inventario Físico."}
                </div>
              </div>

              {physicalInventoryCheck && (
                <div className={styles.contTextData}>
                  <div className={styles.contTextinput}>
                    <div className={styles.contFlex}>
                      <div className={styles.contTitle}>Cantidad</div>
                      <input
                        type="text"
                        name={"opcInvFisCount"}
                        onBlur={handleformat}
                        pattern="[0-9 _,]*"
                        className={styles.inputList}
                        value={opcInvFisCount}
                        onChange={(e) => handleMateInput(e)}
                      />
                    </div>
                    <div className={styles.contFlex}>
                      <div className={styles.contTitle}>Precio</div>
                      <input type="text" pattern="[0-9]{0,13}" name={"opcInvFisPrice"} className={styles.inputList} value={opcInvFisPrice} />
                    </div>
                    <div className={styles.contFlex}>
                      <div className={styles.contTitle}>Total</div>
                      <input
                        type="text"
                        pattern="[0-9]{0,13}"
                        name={"countBox"}
                        className={styles.inputList}
                        value={handleMult(opcInvFisCount, opcInvFisPrice)}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.contTextData} data-aos="flip-down">
                <div className={styles.labelTitleData}>
                  <Checkbox
                    onChange={() => {
                      setAdvisoryCheck(!advisoryCheck);
                    }}
                    className={styles.checkBox}
                    checked={advisoryCheck}
                  />
                  {"Asesoría."}
                </div>
              </div>
              {advisoryCheck && (
                <div className={styles.contTextData} data-aos="flip-down">
                  <div className={styles.labelTitleData}>
                    <textarea
                      rows="4"
                      cols="50"
                      style={{ width: "100%" }}
                      onChange={(e) => setOpcAdvisory(e.target.value)}
                      value={opcAdvisory}
                      className={styles.inputSearch}
                      placeholder="Describa el tipo de asesoría que necesita."
                    />
                  </div>
                </div>
              )}
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8} floated="left">
              <div className={styles.titleFoot} data-aos="flip-up">
                COMPARAR PLANES
              </div>
              <div className={styles.contPlan} data-aos="flip-up">
                <List divided inverted celled className={styles.contListPlan}>
                  <List.Item>
                    <List.Content floated="right" className={styles.contList}>
                      <List.Content className={styles.labelDataDesc}>Básico</List.Content>
                      <List.Content className={styles.labelDataDesc}>Avanzado</List.Content>
                      <List.Content className={styles.labelDataDesc}>Profesional</List.Content>
                    </List.Content>
                  </List.Item>
                  {renderListPlan()}
                </List>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <div className={styles.contComment} data-aos="flip-down">
                <div className={styles.comment}>
                  {/* <div className={styles.titleFoot}>Planes</div> */}
                  <div className={styles.contTextDataDesc}>
                    {/* <div className={styles.labelTitleDesc}>{"Básico:"}</div> */}
                    <span className={styles.labelTitleDesc}>{"Todos los precios son en dólares"}</span>
                  </div>

                  <div className={styles.contTextDataDesc}>
                    {/* <div className={styles.labelTitleDesc}>{"Profeccional:"}</div> */}
                    <span className={styles.labelTitleDesc}>{"ITBIS no está incluido en el precio."}</span>
                  </div>
                  <div className={styles.contTextDataDesc}>
                    {/* <div className={styles.labelTitleDesc}>{"Avanzado:"}</div> */}
                    <span className={styles.labelTitleDesc}>
                      {"La activación tienen un tiempo de duración de 30 días, 1 almacén, 1 usuario, soporte Remoto Telefónico y por Whatsapp."}
                    </span>
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <Contact
        planBool={
          !isEmpty(radioButtom) ||
          integrationSystemsCheck ||
          barcodePrice ||
          organizeProductsSectionsCheck ||
          assignLocationsCheck ||
          physicalInventoryCheck ||
          advisoryCheck
        }
        handleImage={handleDownloadImage}
        plan={radioButtom}
        messageValue={messageValue}
        activeName={"Konteo"}
      />
    </div>
  );
};

export default KonteoCalculate;
