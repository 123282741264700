import React from "react";
import { Modal, Button, List, Icon } from "semantic-ui-react";

//Styles
import styles from "./styles.module.css";
//Constant

const TextPolitc = (props) => {
  const { closeModal, boolPolitic } = props;

  return (
    <Modal open={true} size={"small"} closeIcon onClose={() => closeModal()}>
      <Modal.Header className={styles.header}>
        <Icon name="book"></Icon>
        <div className={styles.titleText}>{boolPolitic ? "Terminos de Uso" : `Política de Privacidad`}</div>
      </Modal.Header>
      {boolPolitic ? (
        <Modal.Content className={styles.content} scrolling>
          <p className={styles.textCont}>
            Estos Términos y Condiciones de Uso General (“Términos de Uso”) regulan el uso del sitio web https://www.ikompras.net (en lo adelante iKompras),
            website informativo propiedad de iKompras. Por favor, lea los siguientes Términos antes de hacer uso de este website. El uso posterior del website
            significa la aceptación de los Términos de Uso. El usuario se compromete a utilizar el website y los servicios de conformidad con la ley y con estos
            Términos de Uso. Asimismo, se obliga a abstenerse de utilizar el website y los servicios con fines o efectos ilegales, como reproducción sin
            autorización que corresponde con el derecho de autor. El usuario responderá de los daños y perjuicios de toda naturaleza que ikompras.net pueda
            sufrir, directa o indirectamente, como consecuencia del incumplimiento de cualquiera de las obligaciones derivadas de estos Términos de Uso.
            Ikompras.net se reserva el derecho a denegar o retirar el acceso al website y/o a los servicios, en cualquier momento y sin necesidad de previo
            aviso a aquellos usuarios que incumplan estos Términos de Uso. El usuario reconoce y acepta que el uso y/o la interpretación de la información
            brindada en este website o de terceros sitios citados como fuentes de información incluidas en este website, es a su sólo y exclusivo riesgo. La
            información contenida en ikompras.net, hace referencia a informaciones, productos y servicios de iKompras. Dicha información es propiedad de
            iKompras, por lo que el usuario se compromete a no redistribuir, retransmitir, divulgar, copiar, modificar, publicar, vender o reproducir la
            información, productos o servicios brindada en este sitio. Asimismo, el usuario se compromete a usar los servicios sólo para uso y beneficio
            personal y no comercial. Los usuarios de ikompras.net que accedan a informaciones, productos y/o servicios ofrecidos por iKompras, acuerdan
            someterse a todas las leyes, resoluciones y normas tanto las vigentes, como las que se aprueben en el futuro en la República Dominicana. El nombre
            de iKompras y logotipo son propiedad y marcas registradas de iKompras, los usuarios no podrán utilizar ninguna marca registrada sin el
            consentimiento por escrito de iKompras y/o de los proveedores de productos y servicios afiliados al sitio. Toda la información y contenido
            incluyendo los programas de Software disponibles en o a través de ikompras.net, están protegidos. A los usuarios de este servicio les está prohibido
            modificar, distribuir, transmitir, exhibir, publicar, vender, licenciar, crear trabajos derivados o utilizar cualquier contenido disponible en o a
            través de esta dirección para fines públicos o comerciales. Todo el contenido, productos y servicios en esta dirección u obtenidos de un sitio
            enlazado a ésta se ofrecerán al usuario en las condiciones “en que están disponibles” sin garantía de ningún tipo. En tal sentido, bajo ninguna
            circunstancia ikompras.net ni iKompras, o ni otra compañía cuya información esté aquí contenida en esta dirección, serán responsables por las
            pérdidas o daños que surjan de la autorización de parte de un usuario de la información aquí contenida ni asumen responsabilidad alguna con los
            contenidos y servicios comerciales o de otra índole de sitios webs que se puedan enlazar electrónicamente (link) directa o indirectamente a través
            ikompras.net. Es responsabilidad del usuario evaluar la fidelidad, exactitud y utilidad de cualquier opinión, consejo u otro contenido disponible a
            través de la página. El usuario debe buscar la opinión de profesionales, cuando lo considere apropiado referente a la evaluación de cualquier
            opinión, consejo, producto, servicio u otro contenido. La información, software, productos, imágenes y descripciones de servicios , estados de
            cuenta y productos publicados en Ikompras.net pueden contener imprecisiones o errores tipográficos y iKompras y ni cualquier otra compañía que cuya
            información aquí contenida en esta dirección, no asumen responsabilidades por tales imprecisiones o errores. Para fines de autorización de
            reproducción favor contacte a: admin@ikompras.net.
          </p>
        </Modal.Content>
      ) : (
        <Modal.Content className={styles.content} scrolling>
          <div className={styles.textCont}>
            Política de Privacidad Estas políticas de privacidad definen como iKompras usa y protege cualquier información que el usuario provea a iKompras
            cuando esté usando este website o portal de internet. iKompras está comprometido a asegurarle que su privacidad está protegida. iKompras podría
            requerir ciertas informaciones mediante las cuales usted puede ser identificado cuando esté usando este website, por ende le aseguramos que dicha
            información sólo será utilizada de acuerdo a lo descrito en esta declaración. iKompras podría cambiar los términos de referencia de este contrato,
            cada cierto tiempo al actualizar su website. Usted debe leer regularmente esta póliza para asegurarse de que está de acuerdo con cualquier
            modificación realizada. Esta póliza es efectiva desde Enero 1 del año 2021.
          </div>
          <div className={styles.textSubtitle}>¿Qué información le solicitamos? </div>
          <List bulleted>
            <List.Item className={styles.textCont}> Nombre.</List.Item>
            <List.Item className={styles.textCont}> Teléfono </List.Item>
            <List.Item className={styles.textCont}> Información de contacto incluyendo dirección de correo electrónico (email).</List.Item>
          </List>
          <div className={styles.textSubtitle}>¿Qué hacemos con la información?</div>
          <div className={styles.textCont}>
            Requerimos este tipo de información para entender sus necesidades y proveer un mejor servicio, y en particular por las siguientes razones:
          </div>
          <List bulleted>
            <List.Item className={styles.textCont}>Como registro interno de usuarios.</List.Item>
            <List.Item className={styles.textCont}> Para mejorar nuestros productos y servicios. </List.Item>
          </List>
        </Modal.Content>
      )}
      <Modal.Actions>
        <Button className={styles.positiveButton} color={"blue"} onClick={() => closeModal()}>
          {"Salir"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default TextPolitc;
