import React, { useState, useEffect, useRef } from "react";
import { isEmpty } from "lodash";
import { Grid, Checkbox } from "semantic-ui-react";
import styles from "./styles.module.css";
import AOS from "aos";
import Contact from "../../components/Contact";
import html2canvas from "html2canvas";
const RastreoQuote = (props) => {
  const componentRef = useRef(null);
  const { messageValue } = props;
  const [dataBase, setdataBase] = useState("");
  const [service, setService] = useState("");
  const [system, setSystem] = useState("");
  const [countMovile, setCountMoviles] = useState("");
  const [projectCheck, setProjectCheck] = useState(false);
  const [optimiRouteCheck, setOptimiRaouteCheck] = useState(false);
  const [taskCheck, setTaskCheck] = useState(false);
  const [orderCheck, setOrderCheck] = useState(false);
  const [apiCheck, setApiCheck] = useState(false);

  const handleDownloadImage = async () => {
    const element = componentRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL();

    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      const blobImage = DataURIToBlob(data);
      return blobImage;
    }
  };
  const DataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div>
      <div id={"price"} ref={componentRef}>
        <Grid className={styles.contContact}>
          <Grid.Row className={styles.contContactRow} centered>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div className={styles.checkCalc}>
                <div className={styles.titleContactForm}>COTIZAR</div>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16} className={styles.columnForm}>
              <div className={styles.contForm} id={"contact"} data-aos="flip-left">
                <div className={styles.titleSend}>Explique brevemente los servicios que presta la empresa</div>
                <textarea
                  className={styles.input}
                  id="w3review"
                  name="w3review"
                  onChange={(e) => setService(e.target.value)}
                  value={service}
                  rows="4"
                  cols="50">
                  At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies.
                </textarea>
                <div className={styles.titleSend}>Nombre del sistema que utiliza la empresa</div>
                <input type="text" onChange={(e) => setSystem(e.target.value)} value={system} className={styles.input} />
                <div className={styles.titleSend}>Nombre de la Base de Datos</div>
                <input type="text" onChange={(e) => setdataBase(e.target.value)} value={dataBase} className={styles.input} />
                <div className={styles.titleSend}>Cantidad de móviles a utilizar</div>
                <input type="text" onChange={(e) => setCountMoviles(e.target.value)} value={countMovile} className={styles.input} />
                <div className={styles.contTextData}>
                  <div className={styles.titleSend}>Chequee las opciones para las que aplica su empresa</div>
                  <div className={styles.contCheck}>
                    <div>
                      <div className={styles.labelTitleData}>
                        <Checkbox
                          onChange={() => {
                            setProjectCheck(!projectCheck);
                          }}
                          className={styles.checkBox}
                          checked={projectCheck}
                        />
                        {"Manejo de Proyectos."}
                      </div>

                      <div className={styles.contTextData}>
                        <div className={styles.labelTitleData}>
                          <Checkbox
                            onChange={() => {
                              setApiCheck(!apiCheck);
                            }}
                            className={styles.checkBox}
                            checked={apiCheck}
                          />
                          {"Integración con API."}
                        </div>
                      </div>

                      <div className={styles.contTextData}>
                        <div className={styles.labelTitleData}>
                          <Checkbox
                            onChange={() => {
                              setOptimiRaouteCheck(!optimiRouteCheck);
                            }}
                            className={styles.checkBox}
                            checked={optimiRouteCheck}
                          />
                          {"Optimización de Ruta."}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.labelTitleData}>
                        <Checkbox
                          onChange={() => {
                            setTaskCheck(!taskCheck);
                          }}
                          className={styles.checkBox}
                          checked={taskCheck}
                        />
                        {"Tareas."}
                      </div>

                      <div className={styles.contTextData}>
                        <div className={styles.labelTitleData}>
                          <Checkbox
                            onChange={() => {
                              setOrderCheck(!orderCheck);
                            }}
                            className={styles.checkBox}
                            checked={orderCheck}
                          />
                          {"Pedidos."}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <Contact
        planBool={
          !isEmpty(dataBase) ||
          !isEmpty(service) ||
          !isEmpty(system) ||
          !isEmpty(countMovile) ||
          projectCheck ||
          optimiRouteCheck ||
          taskCheck ||
          orderCheck ||
          apiCheck
        }
        handleImage={handleDownloadImage}
        plan={"quote"}
        messageValue={messageValue}
        activeName={"Rastreo"}
      />
    </div>
  );
};

export default RastreoQuote;
