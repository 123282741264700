import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import RastreoDesc from "../../components/RastreoDesc";
import RastreoFeatures from "../../components/RastreoFeatures";
import RastreoQuote from "../../components/RastreoQuote";
import logoiKompras from "../../assets/logoiKompras.png";
import RastreoPlanningRouter from "../../components/RastreoPlanningRouter";
import { Icon, Message } from "semantic-ui-react";

import Footer from "../../components/Footer";
import styles from "./styles.module.css";
const Rastreo = () => {
  const [message, setMessage] = useState(false);
  const messageValue = (info) => {
    setMessage(info);
  };
  return (
    <div>
      {message && (
        <div className={styles.mensagge}>
          <Message className={styles.mensaggeCont} size="massive" icon color="blue">
            <Icon name="circle notched" loading />

            <Message.Content>
              <Message.Header> Cargando...</Message.Header>
            </Message.Content>
          </Message>
        </div>
      )}
      <Navbar logo={logoiKompras} activeName={"Rastreo"} />
      <RastreoDesc />
      <RastreoFeatures />
      <RastreoPlanningRouter />
      <RastreoQuote messageValue={messageValue} />

      <Footer />
    </div>
  );
};

export default Rastreo;
