import React, { useEffect } from "react";

import { Grid } from "semantic-ui-react";

import styles from "./styles.module.css";
// import AOS from "aos"
import AOS from "aos";
const KonteoDesc = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      // values from 0 to 3000, with step 50ms
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Grid centered className={styles.contWe} verticalAlign={"middle"}>
      <Grid.Row centered className={styles.contContactRow}>
        <Grid.Column columns={16}>
          <div className={styles.conWidthTitle} data-aos="fade-down">
            <div className={styles.conTitle}>RASTREO APP</div>
          </div>
          <div className={styles.conWidth} data-aos="fade-down">
            <div className={styles.textLetter}>Sistema para dar seguimiento a Proyectos, Repartidores, Supervisores y Técnicos en tiempo real</div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default KonteoDesc;
