import React, { useEffect } from "react";

import { Grid, Image } from "semantic-ui-react";
import alert from "../../assets/rastreo/alert.jpg";
import api from "../../assets/rastreo/api.jpg";
import deliver from "../../assets/rastreo/deliver.jpg";
import fallow from "../../assets/rastreo/fallow.jpg";
import controlProject from "../../assets/rastreo/controlProject.jpg";
import login from "../../assets/rastreo/login.jpg";
import performace from "../../assets/rastreo/performace.jpg";
import repot from "../../assets/rastreo/repot.png";
import send from "../../assets/rastreo/send.jpg";
import test from "../../assets/rastreo/test.jpg";
import time from "../../assets/rastreo/time.jpg";

import plannigRouter from "../../assets/rastreo/plannigRouter.jpg";
import styles from "./styles.module.css";
import AOS from "aos";

const RastreoFeatures = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const renderCard = () => {
    const features = [
      [
        { title: "Control de Proyectos", imgVar: controlProject },
        {
          title: "Seguimiento en Tiempo Real",
          imgVar: send,
        },
        { title: "Alertas", imgVar: alert },
        {
          title: "Envio Automatizado de Notificaciones",
          imgVar: fallow,
        },
        {
          title: "Evaluación de Servicio",
          imgVar: test,
        },
        { title: "Analizar el rendimiento", imgVar: performace },
      ],
      [
        { title: "Integración con API", imgVar: api },
        { title: "Consultas y Reportes", imgVar: repot },

        {
          title: "Registro de Motivos",
          imgVar: time,
        },
        {
          title: "Aplicación Móvil",
          imgVar: login,
        },

        {
          title: "Prueba de Entrega",
          imgVar: deliver,
        },
        {
          title: "Optimización de Rutas",
          imgVar: plannigRouter,
        },
      ],
    ];
    let targetCard = [];

    for (let i = 0; i < 2; i++) {
      const cardChanel = features[i].map((item, index) => (
        <div className={styles.card} key={index}>
          <div className={styles.cardHeaderCont}>
            <Image className={styles.cardHeader} src={item.imgVar} circular />
          </div>
          <div className={styles.cardBody}>
            <h2 className={styles.textLetter}>{item.title}</h2>
          </div>
        </div>
      ));
      targetCard[i] = cardChanel;
    }
    return (
      <div>
        <div className={styles.cardCont}>{targetCard[0]}</div>
        <div className={styles.cardCont}>{targetCard[1]}</div>
      </div>
    );
  };
  return (
    <Grid className={styles.segProduct}>
      <Grid.Row className={styles.contContactRow} centered>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <div id={"features"} className={styles.titleProduct}>
            CARACTERÍSTICAS
          </div>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={16} className={styles.columnCenter}>
          <div>{renderCard()} </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default RastreoFeatures;
