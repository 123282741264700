import React, { useState, useEffect } from "react";

import { Carousel } from "react-responsive-carousel";
import styles from "./styles.module.css";
//Components

import { Grid } from "semantic-ui-react";
import AOS from "aos";
// import fondo from "../../assets/fondo.jpg"
const SliderImg = (props) => {
  const [effect, setEffect] = useState(false);
  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
      anchorPlacement: "top-bottom-left-right",
      mirror: false,
      disabled: false,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    AOS.refresh();
  }, [effect]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid className={styles.grid}>
      <Grid.Column widths={16} className={styles.column}>
        <Carousel
          className={styles.carouselCont}
          infiniteLoop={true}
          showArrows={true}
          showThumbs={false}
          autoPlay={true}
          interval={9000}
          transitionTime={2000}
          direction={"SCROLL_DIRECTION.H"}
          onChange={() => setEffect(!effect)}
          // height={window.innerHeight}
        >
          <div className={styles.imgWe}>
            <div className={styles.contContactRow}>
              <div>
                <div data-aos="fade-in" className={styles.titleSlider}>
                  SOLUCIONES TECNOLOGICAS
                </div>
                <p data-aos="fade-in" className={styles.contSlider}>
                  Conoces todas las aplicaciones que tenemos a tu alcance que pueden ayudar en el día a día de tu empresa.
                </p>
              </div>
            </div>
          </div>

          <div className={styles.imgWe2}>
            <div className={styles.contContactRow}>
              <div>
                <div data-aos="fade-in" className={styles.titleSlider}>
                  SOLUCIONES TECNOLOGICAS
                </div>
                <p data-aos="fade-in" className={styles.contSlider}>
                  Conoces todas las aplicaciones que tenemos a tu alcance que pueden ayudar en el día a día de tu empresa.
                </p>
              </div>
            </div>
          </div>

          <div className={styles.imgWe3}>
            <div className={styles.contContactRow}>
              <div>
                <div data-aos="fade-in" className={styles.titleSlider}>
                  SOLUCIONES TECNOLOGICAS
                </div>
                <p data-aos="fade-in" className={styles.contSlider}>
                  Conoces todas las aplicaciones que tenemos a tu alcance que pueden ayudar en el día a día de tu empresa.
                </p>
              </div>
            </div>
          </div>
        </Carousel>
      </Grid.Column>
    </Grid>
  );
};

export default SliderImg;
