import React, { useEffect } from "react";

import { Grid, Image } from "semantic-ui-react";

import konteohow from "../../assets/konteo/konteohow.svg";
import styles from "./styles.module.css";
import AOS from "aos";

const KonteoHow = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // values from 0 to 3000, with step 50ms
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Grid className={styles.segProduct}>
      <Grid.Column width={16}>
        <div data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500" id={"how"} className={styles.titleProduct}>
          ¿COMO FUNCIONA?
        </div>
      </Grid.Column>

      <Grid.Column width={16}>
        <div className={styles.titleProduct}>
          <Image className={styles.logo} src={konteohow} target="_blank" />
        </div>
      </Grid.Column>
    </Grid>
  );
};

export default KonteoHow;
