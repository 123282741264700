import React, { useState, useEffect } from "react";
import { Form, Icon, Grid, Header } from "semantic-ui-react";
import axios from "axios";
import { isEmpty } from "lodash";
import AOS from "aos";
import { isPossiblePhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import FormData from "form-data";
import PropTypes from "prop-types";
import { phoneFormat } from "../../constants/formats";
import styles from "./styles.module.css";
var validator = require("email-validator");

const Contact = (props) => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [recarge, setRecarge] = useState(true);
  const [validateMail, setValidateMail] = useState(false);
  const [validatePhone, setValidatePhone] = useState(false);
  const { planBool, plan, handleImage, messageValue, activeName } = props;
  useEffect(() => {
    AOS.init({
      duration: 2000,
      // values from 0 to 3000, with step 50ms
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const handlerStringPlan = (string) => {
    switch (string) {
      case "advanced":
        return "Avanzado";

      case "professional":
        return "Profesional";

      case "basic":
        return "Básico";

      case "quote":
        return "Cotización";
      case "maxmonitor":
        return "Cotización";
      default:
        break;
    }
  };
  const handlerStringActiveName = (string) => {
    switch (string) {
      case "Rastreo":
        return "Sin Cotización";

      case "Konteo":
        return "No tiene Plan";
      case "MaxMonitor":
        return "Sin Cotización";
      default:
        break;
    }
  };
  const sendEmail = async () => {

    const isPossible = isPossiblePhoneNumber(phone, "DO");
    const isPossibleMail = validator.validate(mail);
    setValidatePhone(!isPossible);
    setValidateMail(!isPossibleMail);
    if (isPossibleMail && isPossible) {
      messageValue(true);
      setRecarge(false);
      const form = new FormData();

      form.append(
        "data",
        JSON.stringify({
          email: mail,
          name: name,
          message: message,
          phone: parsePhoneNumber(phone, "DO").number,
          company: company,
          subject: activeName,
        })
      );
      if (planBool || !isEmpty(plan)) {
        const image = await handleImage();

        form.append("file", image);
      }
      await axios
        .post("https://rastreo.wsmax.net:9050/emails/ikompras", form)
        .then(function (response) {
          setName("");
          setMail("");
          setMessage("");

          setCompany("");
          messageValue(false);
          setPhone("");
          setRecarge(true);
        })
        .catch(function (error) {
          console.log(error);
          messageValue(false);
          setRecarge(true);
        });
    }
  };
  return (
    <Grid className={styles.contContact}>
      <Grid.Row className={styles.contContactRow}>
        <Grid.Column mobile={16} tablet={16} computer={8} verticalAlign="middle">
          <Header as="h1" data-aos="flip-left">
            <div className={styles.titleContact}>¿Deseas más información?</div>
          </Header>
          <div className={styles.textContact} data-aos="flip-left">
            <p>Con gusto aclareremos todas sus dudas</p>
          </div>
        </Grid.Column>

        <Grid.Column mobile={16} tablet={16} computer={8} className={styles.columnForm}>
          <div className={styles.contForm} id={"contact"} data-aos="flip-left">
            <div className={styles.checkCalc}>
              <div className={styles.titleContactForm}>CONTACTANOS</div>
              {activeName !== "Home" && (
                <div>
                  {!planBool && (
                    <div className={styles.checkCalcText}>
                      <Icon name="square outline" size="big" /> <span>{handlerStringActiveName(activeName)}</span>
                    </div>
                  )}
                  {planBool && (
                    <div className={styles.checkCalcText}>
                      <Icon name="check square outline" size="big" />{" "}
                      {!isEmpty(plan) ? <span>{handlerStringPlan(plan)}</span> : <span>{handlerStringActiveName(activeName)}</span>}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={styles.titleSend}>Nombre</div>
            <input type="text" onChange={(e) => setName(e.target.value)} value={name} className={styles.input} />
            <div className={styles.titleSend}>Empresa</div>
            <input type="text" onChange={(e) => setCompany(e.target.value)} value={company} className={styles.input} />
            <div className={styles.titleSend}>Email {validateMail && <span className={styles.titleErrors}>{"(Correo Invalido)"}</span>}</div>
            <input type="text" onChange={(e) => setMail(e.target.value)} value={mail} className={styles.input} />
            <div className={styles.titleSend}>Teléfono {validatePhone && <span className={styles.titleErrors}>{"(Teléfono Invalido)"}</span>}</div>
            <input type="text" onChange={(e) => setPhone(e.target.value)} value={phoneFormat(phone)} className={styles.input} />
            <div className={styles.titleSend}>Mensaje</div>
            <textarea className={styles.input} id="w3review" name="w3review" onChange={(e) => setMessage(e.target.value)} value={message} rows="4" cols="50">
              At w3schools.com you will learn how to make a website. They offer free tutorials in all web development technologies.
            </textarea>
            <Form inverted className={styles.flexForm}>
              <div className={styles.titleCheck}> Estoy de acuerdo con los términos y condiciones al enviar este email</div>

              <Form.Button
                color={"green"}
                disabled={isEmpty(mail) || isEmpty(message) || isEmpty(phone) || isEmpty(company) || isEmpty(name) || !recarge}
                onClick={() => sendEmail()}>
                <Icon name={"send"} />
                Enviar
              </Form.Button>
            </Form>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
Contact.propTypes = {
  planBool: PropTypes.bool,
  plan: PropTypes.string,
  activeName: PropTypes.string,
};

Contact.defaultProps = {
  planBool: false,
  plan: "",
  activeName: PropTypes.string,
};
export default Contact;
