import "./App.css";
import Home from "./screens/Home";
import Konteo from "./screens/Konteo";
import Rastreo from "./screens/Rastreo";
import MaxMonitor from "./screens/MaxMonitor";
import { Routes, Route } from "react-router-dom";

import { Dimmer } from "semantic-ui-react";

function App() {
  return (
    <div>
      <Dimmer.Dimmable>
        <Routes>
          <Route path="/" caseSensitive={false} element={<Home />} />
          <Route path="/konteo" caseSensitive={false} element={<Konteo />} />
          <Route path="/rastreo" caseSensitive={false} element={<Rastreo />} />
          <Route path="/maxmonitor" caseSensitive={false} element={<MaxMonitor />} />
        </Routes>
      </Dimmer.Dimmable>
    </div>
  );
}

export default App;
