import React, { useState } from "react";
import Navbar from "../../components/Navbar";

import SliderImg from "../../components/SliderImg";
import Product from "../../components/Product";
import We from "../../components/We";
import Contact from "../../components/Contact";
import logoiKompras from "../../assets/logoiKompras.png";
import Footer from "../../components/Footer";
import { Icon, Message } from "semantic-ui-react";
import styles from "./styles.module.css";

const Home = () => {
  const [message, setMessage] = useState(false);
  const messageValue = (info) => {
    setMessage(info);
  };
  return (
    <div>
      {message && (
        <div className={styles.mensagge}>
          <Message className={styles.mensaggeCont} size="massive" icon color="blue">
            <Icon className={styles.messajeIcon} name="circle notched" loading />

            <Message.Content>
              <Message.Header className={styles.messajeLetter}> Cargando...</Message.Header>
            </Message.Content>
          </Message>
        </div>
      )}
      <Navbar logo={logoiKompras} activeName={"Inicio"} />

      <SliderImg />

      <We />
      <Product />
      <Contact activeName={"Home"} messageValue={messageValue} />
      <Footer />
    </div>
  );
};

export default Home;
