import React, { useState } from "react";

import { List, Grid, Header } from "semantic-ui-react";

import styles from "./styles.module.css";
import TextPolitc from "../../modals/TextPolitc";
const Footer = (props) => {
  const [openTextPolitc, setOpenTextPolitc] = useState(false);
  const [textPolitc, setTextPolitc] = useState("");

  return (
    <Grid className={styles.contBackground} centered verticalAlign="middle">
      <Grid.Row columns={3} className={styles.background}>
        <Grid.Column mobile={16} tablet={16} computer={5} textAlign="left">
          <Header inverted as="h1" content="iKompras SRL" />

          <List link inverted>
            <List.Item className={styles.text}>Dirección: Av. Independencia #246, Suite 302, Honduras, Santo Domingo</List.Item>
            <List.Item className={styles.textContNumber}>
              {`Teléfono:`} <div className={styles.textNumber}>(809) 532 - 3233</div> / <div className={styles.textNumber}>(809) 820 - 4040</div>
            </List.Item>
            <List.Item className={styles.text}>Email: admin@ikompras.net</List.Item>
          </List>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={6} verticalAlign="bottom">
          <Header as="h4" inverted className={styles.textSrl}>
            1.0 @2022 iKompras SRL
          </Header>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={5} textAlign="left">
          <List link inverted>
            <List.Item
              onClick={() => {
                setTextPolitc(false);
                setOpenTextPolitc(true);
              }}
              className={styles.textClick}>
              Política de Privacidad
            </List.Item>
            <List.Item
              onClick={() => {
                setTextPolitc(true);
                setOpenTextPolitc(true);
              }}
              className={styles.textClick}>
              Terminos de Uso
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      {openTextPolitc && (
        <TextPolitc
          closeModal={() => {
            setOpenTextPolitc(false);
          }}
          boolPolitic={textPolitc}
        />
      )}
    </Grid>
  );
};

export default Footer;
